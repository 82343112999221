<template>
  <header class="header">
    <div class="inner">
      <div class="grid">
        <div>
          <page-link to="/" class="logo"
            ><img src="/images/carl-stahl-architectural.png" alt="Cable Assemblies and Posilock Display Systems"
          /></page-link>
        </div>
        <div class="contact-opening">
          <div>
            <a
              v-if="settings && settings.telephone"
              :href="'tel:' + settings.telephone.replace(/\D/g, '')"
              class="contact-tel"
              ><font-awesome-icon icon="phone" /><span> {{ settings.telephone }}</span></a
            >
            <span class="opening-times">Open weekdays 9.00AM-5.00PM</span>
          </div>
        </div>
        <div class="search">
          <div>
            <input type="text" v-model="query" @keyup.enter="search" class="search-input" placeholder="Search" />
            <button class="search-button" @click="search" aria-label="Search">
              <font-awesome-icon icon="search" />
            </button>
          </div>
        </div>
        <div class="account">
          <div>
            <!--<router-link to="/account"><font-awesome-icon icon="user" /><span>My Account</span></router-link>-->
            <router-link to="/basket"><font-awesome-icon icon="shopping-cart" /><span>My Basket</span></router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  data: function () {
    return {
      query: ""
    }
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["isHomePage"])
  },
  methods: {
    search() {
      if (!this.query) return

      this.$router.push("search-results?q=" + this.query.toLowerCase())
    }
  }
}
</script>

<style scoped>
.header {
  padding: 2rem 0;
}

.logo {
  display: block;
  width: 20rem;
}

.grid {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1.5fr 0.75fr;
}
.contact-opening,
.search,
.account {
  display: flex;
  align-items: center;
}
.contact-tel {
  font-size: 2.8rem;
  color: var(--primary-color);
  font-weight: bold;
}
.contact-tel svg {
  position: relative;
  margin-right: 0.5rem;
  background: var(--primary-color);
  color: white;
  border-radius: 100%;
  padding: 0.6rem;
  margin-bottom: -0.15rem;
}
.opening-times {
  display: block;
  color: var(--secondary-color);
  font-size: 1.7rem;
  text-transform: uppercase;
}
.search-input {
  border: 0;
  border-bottom: 2px solid var(--tertiary-color);
  font-size: 1.7rem;
  padding: 1rem;
}
.search-input::placeholder {
  color: #ccc;
}
.search-button {
  bottom: -0.1rem;
}
.search-button,
.account svg {
  background: var(--primary-color);
  color: white;
  border: 0;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
}
.search-button:hover {
  transform: scale(1.1);
}
.account {
  text-align: right;
}
.account a {
  display: inline-block;
  margin-right: 2rem;
}
.account svg {
  background: #333;
  padding: 0.6rem;
  vertical-align: middle;
  margin-right: 1rem;
}

@media screen and (max-width: 1090px) {
  .logo {
    position: relative;
    left: 8rem;
    width: 14rem;
  }
  .account a {
    margin-right: 0;
    margin-left: 1rem;
  }
  .search-button {
    margin-right: 1rem;
  }
  .account > div {
    width: 100%;
    text-align: right;
  }
  .account span,
  .opening-times,
  .contact-tel {
    display: none;
  }
  .grid {
    grid-template-columns: 3fr 0fr 3fr 1.5fr;
  }
}

@media screen and (max-width: 650px) {
  .search {
    display: none !important;
  }
  .contact-tel span {
    display: none;
  }
  .grid {
    display: block;
    position: relative;
  }
  .logo {
    width: 9rem;
    left: 5rem;
  }
  .contact-tel {
    position: absolute;
    top: 0.7rem;
    right: 10.5rem;
    display: block;
  }
  .contact-tel svg {
    font-size: 2.9rem;
  }
  .account {
    position: absolute;
    top: 1.1rem;
    right: 0%;
  }
}
</style>
