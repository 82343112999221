<template>
  <div class="inner">
    <section class="grid">
      <page-link :to="product.url" v-for="product in page.products" :key="product.ItemID"
        ><img class="link-image" :alt="product.name" :src="product.thumbnail_image" />
        <span class="link-label">{{ product.name }}</span></page-link
      >
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["page"])
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
}
.grid a {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--primary-color);
}
.link-image {
  padding: 5rem 1rem;
  padding-bottom: 8rem;
  transition: all 0.3s;
  display: block;
  margin: auto;
}
.grid a:hover .link-image {
  transform: scale(1.05);
}
.link-label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  background: var(--primary-color);
  color: white;
  font-size: 2rem;
  padding: 1rem 2.5rem;
  line-height: 1.2;
}

@media screen and (max-width: 770px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    margin-top: 5rem;
  }

  .grid a:hover .link-image {
    transform: none;
  }

  .link-label {
    font-size: 1.7rem;
  }
}
</style>
