<template>
  <div class="icons">
    <div class="inner">
      <div class="grid" v-if="settings">
        <a
          class="icon"
          :class="icon.action ? 'pointer' : ''"
          :href="icon.action ? icon.action : '#'"
          v-for="icon in settings.icons"
          :key="icon._id"
          :aria-label="icon.name"
        >
          <div>
            <font-awesome-icon :icon="icon.icon" /><span>{{ icon.name }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState(["settings"])
  }
}
</script>

<style scoped>
.icons {
  margin-top: 2rem;
  background: rgba(200, 200, 200, 0.05);
  padding: 5rem 0;
}
.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  gap: 5rem;
}
.icon {
  border-radius: 100%;
  display: flex;
  align-items: center;
  margin: auto;
  font-weight: bold;
  cursor: default;
}
.icon.pointer {
  cursor: pointer;
}
.icon > div {
  display: block;
  width: 100%;
}
.icon svg {
  font-size: 6rem;
  margin-bottom: 2rem;
  background: var(--primary-color);
  border: 2rem solid var(--primary-color);
  border-radius: 100%;
  height: 7rem;
  width: 7rem;
  color: #f4f6f6;
}
.icon span {
  display: block;
}

@media screen and (max-width: 980px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid > div:last-child {
    display: none;
  }
}
</style>
