<template>
  <div>
    <header-elem />
    <navigation-elem />
    <breadcrumb-elem v-if="!isHomePage" />
    <categories-elem v-if="isHomePage" />
    <sub-categories-elem v-if="isATopCategory" />
    <content-elem v-if="!isAProduct" />
    <product-elem v-if="isAProduct" />
    <products-elem v-if="isASubCategory" />
    <portfolio-elem v-if="isPortfolioPage" />
    <downloads-elem v-if="isDownloadsPage" />
    <cart-elem v-if="isBasketPage" />
    <checkout-elem v-if="isCheckoutPage" />
    <account-elem v-if="$route.name == 'account'" />
    <contact-elem v-if="isContactPage" />
    <icons-elem />
    <footer-elem />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import dynamodb from "aws-sdk/clients/dynamodb"

import api from "@/services/api"

import HeaderElem from "@/components/Header.vue"
import CategoriesElem from "@/components/Categories.vue"
import NavigationElem from "@/components/Navigation.vue"
import ContentElem from "@/components/Content.vue"
import IconsElem from "@/components/Icons.vue"
import FooterElem from "@/components/Footer.vue"
import SubCategoriesElem from "@/components/SubCategories.vue"
import BreadcrumbElem from "@/components/Breadcrumb.vue"
import ProductsElem from "@/components/Products.vue"
import ProductElem from "@/components/Product.vue"
import PortfolioElem from "@/components/Portfolio.vue"
import DownloadsElem from "@/components/Downloads.vue"
import ContactElem from "@/components/Contact.vue"
import CartElem from "@/components/Cart.vue"
import AccountElem from "@/components/Account.vue"
import CheckoutElem from "@/components/Checkout.vue"

export default {
  name: "page",
  components: {
    HeaderElem,
    CategoriesElem,
    NavigationElem,
    ContentElem,
    IconsElem,
    FooterElem,
    SubCategoriesElem,
    BreadcrumbElem,
    ProductsElem,
    ProductElem,
    PortfolioElem,
    DownloadsElem,
    ContactElem,
    CartElem,
    AccountElem,
    CheckoutElem
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(["page"]),
    ...mapGetters([
      "isHomePage",
      "isATopCategory",
      "isASubCategory",
      "isAProduct",
      "isPortfolioPage",
      "isDownloadsPage",
      "isContactPage",
      "isBasketPage",
      "isCheckoutPage",
      "isSuccess",
      "isFailure"
    ])
  },
  metaInfo() {
    return {
      title: this.page.title,
      meta: [
        { name: "description", content: this.page.meta_desc },
        { name: "keywords", content: this.page.meta_keywords }
      ]
    }
  },
  created: function () {
    var route = this.$route
    var url = route.params.url
    var promises = []
    var store = this.$store
    // var state = this.$store.state

    if (!url) store.commit("setPage", {})

    if (route.name == "404") this.page = { main_text: "Sorry, this page cannot be found!" }

    if (route.name == "page-by-url") promises.push(api.getPageByURL(url))
    if (route.name == "root") promises.push(api.getHomePage())
    if (route.name == "search-results")
      store.commit("setPage", { name: "Search Results", _id: "search", ItemType: "page.categories" })

    Promise.all(promises).then((vals) => {
      var returnedpage = vals[0]
      if (route.name != "search-results") store.commit("setPage", returnedpage)

      if (this.isSuccess || this.isFailure)
        api.updateOrder(this.$route.query.crypt).then((res) => this.$set(this.page, "transaction_response", res.data))

      if ((route.name == "page-by-url" || route.name == "root") && (!returnedpage || !returnedpage.ItemID))
        store.commit("setPage", { main_text: "Sorry, this page cannot be found!" })

      store.dispatch("getParents")

      if (this.isATopCategory)
        api
          .getPages("thumbnail_image", "page.categories=@" + returnedpage.ItemID)
          .then((subs) => this.$set(returnedpage, "subs", subs))

      if (this.isASubCategory) {
        if (route.name == "search-results") {
          api
            .getPages(
              "thumbnail_image",
              "page.products",
              "contains(#search, :search)",
              {
                "#search": "_search"
              },
              {
                ":search": dynamodb.Converter.input(this.$route.query.q.toLowerCase())
              }
            )
            .then((subs) => {
              console.log(subs)
              this.$set(this.page, "products", subs)
            })
        } else {
          api
            .getPages("thumbnail_image", "page.products=@" + returnedpage.ItemID)
            .then((subs) => this.$set(returnedpage, "products", subs))
        }
      }
    })

    store.dispatch("getSettings")
    store.dispatch("getMainPages")
  }
}
</script>
