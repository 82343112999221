<template>
  <div>
    <div class="inner">
      <section class="grid">
        <div>
          <contact-form></contact-form>
        </div>
        <div class="contact-info" v-if="settings">
          <p v-if="settings.company && settings.address">
            <strong>{{ settings.company }}</strong
            ><br /><span v-html="settings.address.replace(/[\n\r]/g, '<br />')"></span>
          </p>

          <p v-if="settings.email">
            <strong>Email:</strong>&nbsp;<a :href="'mailto:' + settings.email">{{ settings.email }}</a>
          </p>

          <p v-if="settings.telephone">
            <strong>Telephone:</strong>&nbsp;<a :href="'tel:' + settings.telephone.replace(/\D/g, '')">{{
              settings.telephone
            }}</a>
          </p>
        </div>
      </section>
    </div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9492.282449852773!2d-1.3208753!3d53.502934!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7bf4f5216ecf059f!2sCarl%20Stahl%20Evita%20Limited!5e0!3m2!1sen!2suk!4v1643199225261!5m2!1sen!2suk"
      width="100%"
      height="550"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      class="map"
    ></iframe>
  </div>
</template>

<script>
import { mapState } from "vuex"
import ContactForm from "./ContactForm.vue"

export default {
  components: { ContactForm },
  computed: {
    ...mapState(["settings"])
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  margin-bottom: 5rem;
}

.contact-info {
  font-size: 2rem;
}

.contact-info p {
  margin-bottom: 2rem;
}
.contact-info a {
  color: var(--primary-color);
}

.map {
  margin-bottom: -2.5rem;
  margin-top: 1rem;
}

@media screen and (max-width: 770px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 3rem;
  }
  .map {
    height: 45rem;
  }
}
</style>
