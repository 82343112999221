<template>
  <section class="content">
    <div class="inner"></div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  }
}
</script>

<style scoped>
.content {
  padding-bottom: 5rem;
}

@media screen and (max-width: 770px) {
  .content {
    padding-bottom: 0;
  }
  .content.padded {
    padding-top: 3rem;
  }
}
</style>
