<template>
  <footer class="footer">
    <div class="inner">
      <div class="top">
        <div class="lists">
          <div class="list" v-for="categoryPage in categoryPages" :key="categoryPage.ItemID">
            <page-link class="heading" :to="categoryPage.url"
              ><font-awesome-icon icon="chevron-circle-down" /> {{ categoryPage.name }}</page-link
            >
            <page-link v-for="subPage in categoryPage.subs" :key="subPage.ItemID" :to="subPage.url"
              ><font-awesome-icon icon="chevron-right" /> {{ subPage.name }}</page-link
            >
          </div>
        </div>
        <router-link to="/" class="logo"
          ><img src="/images/carl-stahl-architectural.png" alt="Cable Assemblies and Posilock Display Systems"
        /></router-link>
        <img src="/images/cards.png" class="cards" alt="All major cards accepted" />
        <img class="bsi" src="/images/bsi.png" alt="ISO 9001" />
      </div>
      <div class="legal">
        <div class="legal-right">
          Prices shown exclude VAT - this will be added at checkout<br />

          <template v-for="(footerPage, i) in footerMenu"
            ><page-link :key="footerPage.ItemID + '-link'" :to="footerPage.url">{{ footerPage.name }}</page-link
            ><span v-if="i < footerMenu.length - 1" :key="footerPage.ItemID + '-span'"> | </span></template
          >
        </div>
        © {{ new Date().getFullYear() }} {{ settings && settings.company }}<br />
        {{ settings && settings.address && settings.address.replace(/[\n\r]/g, " ") }}
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapState } from "vuex"

export default {
  computed: {
    ...mapGetters(["categoryPages", "footerMenu"]),
    ...mapState(["settings"])
  }
}
</script>

<style scoped>
.footer {
  padding-top: 7rem;
  padding-bottom: 3rem;
  background: var(--tertiary-color);
}

.logo {
  display: block;
  width: 15rem;
}

.grid {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr;
}

.top {
  width: 100%;
  display: table;
}

.lists {
  float: right;
  display: grid;
  width: 55%;
  grid-template-columns: 1fr 1fr 1fr;
}

.list a.heading {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.8rem;
}

.list a {
  display: block;
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

.list a svg {
  margin: 0 0.5rem;
  opacity: 0.25;
}

.list a.heading svg {
  margin: 0 0.1rem;
  opacity: 1;
}

.legal {
  margin-top: 8rem;
  font-size: 1.2rem;
  color: rgb(150, 150, 150);
}

.bsi {
  width: 12rem;
  opacity: 0.2;
  margin-top: 5rem;
}

.legal-right {
  float: right;
  text-align: right;
}

.footer a:hover {
  color: var(--primary-color);
}

.cards {
  width: 30rem;
  margin-top: 5rem;
  display: block;
}

@media screen and (max-width: 1090px) {
  .lists {
    float: none;
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .legal {
    text-align: center;
  }
  .legal-right {
    float: none;
    text-align: center;
    margin-bottom: 3rem;
  }
  .logo {
    margin: auto;
    margin-top: 7rem;
  }
  .bsi,
  .cards {
    display: block;
    margin: auto;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 650px) {
  .lists {
    display: none;
  }
  .footer {
    padding-top: 0;
  }
}
</style>
