<template>
  <section class="content product">
    <div class="inner">
      <h1>{{ page.name }}</h1>
      <div class="split">
        <div>
          <span v-if="page.main_text" v-html="page.main_text.replaceAll('<p>&nbsp;</p>', '')"></span>
          <img :src="page.detail_image" :alt="page.name" />
        </div>
        <div v-if="variants.length">
          <div class="buy-wrapper">
            <h3>Buy Assembly</h3>
            <table class="buy">
              <tr>
                <td>Choose a type:</td>
                <td>
                  <select v-model="variant" @input="changeVariant">
                    <option v-for="variant in variants" :key="variant._id" :value="variant">
                      {{ variant.name }} (&#163;{{ Number(variant.cost).toFixed(2) }})
                    </option>
                  </select>
                </td>
              </tr>
              <tr v-if="needsLength">
                <td>Specify length (KI):</td>
                <td>
                  <input
                    type="number"
                    :min="variant ? variant.min_length : 0"
                    class="qty-mm"
                    step="10"
                    v-model.number="length_mm"
                  />
                  mm
                </td>
              </tr>
              <tr>
                <td>Specify quantity:</td>
                <td><input type="number" v-model.number="qty" /></td>
              </tr>
              <tr>
                <td colspan="2">
                  <span class="error" v-if="errormessage">{{ errormessage }}</span>
                  <button @click="addToCart">Add to Basket<font-awesome-icon icon="shopping-cart" /></button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="specs-holder" v-html="page.specs_text"></div>
    </div>
    <div class="added" :class="added ? 'display' : ''">
      <div class="added-text">Added to basket!</div>
      <div class="added-actions">
        <router-link to="/basket">Go to basket</router-link><a href @click.prevent="added = false">Continue Shopping</a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import shortid from "shortid"

export default {
  data: function () {
    return { errormessage: "", qty: "", length_mm: "", variant: null, added: false }
  },
  computed: {
    ...mapState(["page", "parents", "assembliesID", "wireRopeID"]),
    ...mapGetters(["isHomePage"]),
    needsLength() {
      if (!this.parents.length) return false
      return this.parents[0].ItemID == this.assembliesID || this.parents[0].ItemID == this.wireRopeID
    },
    variants() {
      if (!this.page.variants) return []
      if (!this.page.variants.length) return []

      var product_variants = this.page.variants
      product_variants = product_variants.sort(function (a, b) {
        return Number(a.cost) - Number(b.cost)
      })
      return product_variants
    }
  },
  methods: {
    addToCart() {
      this.errormessage = ""

      if (!this.variant) return (this.errormessage = "Please choose a type")

      if (this.needsLength) {
        if (this.length_mm <= 0) return (this.errormessage = "Please enter a valid length")
        if (this.variant.min_length && this.length_mm < this.variant.min_length)
          return (this.errormessage = "The minimum length for this variant is " + this.variant.min_length + " mm")
      }

      this.qty = Math.floor(this.qty)
      if (this.qty < 1) return (this.errormessage = "Please enter a valid quantity")

      var _id = shortid.generate()
      var variant = JSON.parse(JSON.stringify(this.variant))

      var item = {
        _id,
        product_id: this.page.ItemID,
        sub_category_id: this.page.ParentID,
        top_category_id: this.parents[0].ItemID,
        variant,
        qty: this.qty
      }
      if (this.needsLength) item.length_mm = this.length_mm

      this.$store.commit("addBasketItem", item)
      this.$nextTick().then(() => {
        this.added = true
        this.qty = ""
        this.length_mm = ""
        this.variant = null
      })
    },
    changeVariant() {
      this.errormessage = ""
    }
  }
}
</script>

<style scoped>
.added {
  position: fixed;
  top: 50%;
  left: -150%;
  transform: translate(-50%, -50%);
  background: var(--primary-color);
  padding: 2rem;
  color: white;
  text-align: center;
  opacity: 0;
  transition: all 0.5s;
}
.added.display {
  left: 50%;
  opacity: 1;
}
.added-text {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: bold;
}
.added a {
  border: 1px solid white;
  display: inline-block;
  padding: 0.75rem 1.5rem;
}
.added a:first-child {
  margin-right: 3rem;
}
.product {
  padding-bottom: 5rem;
}
.split {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 5rem;
}
.specs-holder >>> table {
  width: 100%;
  font-size: 1.4rem;
  border-collapse: collapse;
  margin-top: 5rem;
}
.specs-holder >>> table td {
  border: 1px solid #f5f5f5;
  padding: 0.2rem 0.5rem;
}
.specs-holder >>> table strong {
  color: var(--primary-color);
}
h3 {
  color: var(--primary-color);
  margin-top: 0.8rem;
}

.buy {
  border-collapse: collapse;
}

.buy-wrapper {
  background: var(--tertiary-color);
  padding: 3rem;
}

.buy td {
  padding: 1rem 0;
  padding-right: 1rem;
}

.buy input,
.buy select {
  padding: 0.5rem 1rem;
}

.buy select {
  cursor: pointer;
}

.buy input {
  width: 7rem;
}

.buy .qty-mm {
  width: 10rem;
}

.buy button {
  background: var(--primary-color);
  border: 0;
  color: white;
  padding: 1rem 2rem;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s;
}

.buy button svg {
  margin-left: 2rem;
}

.buy button:hover {
  transform: scale(1.05);
  filter: brightness(1.05);
}

@media screen and (max-width: 990px) {
  .split {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 770px) {
  .specs-holder {
    overflow: auto;
    position: relative;
    height: 50rem;
  }
  .specs-holder >>> table {
    position: absolute;
    width: 100rem;
  }
  .added-actions a {
    display: block;
    clear: both;
    width: 100%;
  }
  .added-actions a:first-child {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
</style>
