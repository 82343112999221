<template>
  <div class="content">
    <div class="inner">
      <template v-for="category in page.download_categories">
        <h3 :key="'h3-' + category._id"><font-awesome-icon icon="folder-open" /> {{ category.name }}</h3>
        <p :key="'p-' + category._id" v-html="category.description"></p>
        <div :key="'div-' + category._id" class="downloads">
          <a
            :href="download.file"
            target="_blank"
            rel="noreferrer"
            v-for="download in category.downloads"
            :key="download._id"
            ><img class="link-image" :alt="download.name" :src="download.image" />
            <span class="link-label"
              ><font-awesome-icon icon="file-pdf" /> {{ download.name }}<span>{{ download.description }}</span>
            </span></a
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  }
}
</script>

<style scoped>
h3 {
  font-size: 2.3rem;
  margin-bottom: 2.5rem;
  color: var(--primary-color);
  line-height: 1.2;
  background: var(--tertiary-color);
  padding: 1.5rem 2rem;
}

.downloads {
  padding-top: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5rem;
}

p {
  padding-left: 1.5rem;
}

svg {
  margin-right: 1rem;
  color: var(--primary-color);
}

.downloads span {
  display: block;
}

.link-image {
  margin-bottom: 1rem;
}

.link-label {
  font-weight: bold;
  color: #333;
  line-height: 1.2;
}

.link-label > span {
  font-weight: normal;
  color: #333;
  margin-top: 1rem;
  font-size: 1.4rem;
}

@media screen and (max-width: 950px) {
  .downloads {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 770px) {
  .content {
    padding-top: 3rem;
  }
}
@media screen and (max-width: 600px) {
  .downloads {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
</style>
