<template>
  <div class="form">
    <div class="form-body">
      <div class="form-row">
        <input class="txt" placeholder="Name" type="text" v-model="form.name" />
      </div>
      <div class="form-row">
        <input class="txt" placeholder="Company" type="text" v-model="form.company" />
      </div>
      <div class="form-row">
        <input class="txt" placeholder="Email" type="email" v-model="form.email" />
      </div>
      <div class="form-row">
        <input class="txt" placeholder="Telephone" type="tel" v-model="form.tel" />
      </div>
      <div class="form-row">
        <textarea class="txt" placeholder="Message" v-model="form.message"></textarea>
      </div>
      <div class="form-row">
        <span class="success" v-show="emailsuccess">Thanks, your request has been sent!</span>
        <span class="error" v-show="emailerror">{{ errormessage }}</span>
        <button @click="sendEmail">Send message</button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api"

import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  },
  data: function () {
    return {
      emailsuccess: false,
      emailerror: false,
      errormessage: "",
      form: {},
      initial_values: { dropdowns_need_to_be_initialised: "" }
    }
  },
  created: function () {
    this.form = JSON.parse(JSON.stringify(this.initial_values))
  },
  methods: {
    sendEmail: function () {
      this.emailsuccess = false
      this.emailerror = false

      if (!this.form.name || !this.form.email || !this.form.message) {
        this.errormessage = "Please fill in all required fields, thanks!"
        this.emailerror = true
        return false
      }

      var content = `The following message has been sent from the website:<br /><br />`

      content += `Name: ${this.form.name}<br />
                  Email: ${this.form.email}<br />`

      if (this.form.tel) content += `Telephone: ${this.form.tel}<br />`
      content += `Message: ${this.form.message}<br />`

      api
        .sendEmail("Enquiry from the website", content)
        .then(() => {
          this.emailsuccess = true
          this.form = JSON.parse(JSON.stringify(this.initial_values))
        })
        .catch(() => {
          this.errormessage = "There has been an error submitting your message"
          this.emailerror = true
        })
    }
  }
}
</script>

<style scoped>
.form-row {
  margin-bottom: 2rem;
}

.txt {
  width: 100%;
  padding: 1rem;
  border: 1px solid #e5e5e5;
  max-width: 60rem;
}

textarea.txt {
  height: 15rem;
}

button {
  background: var(--primary-color);
  border: 0;
  color: white;
  padding: 1rem 2rem;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s;
}

button svg {
  margin-left: 2rem;
}

button:hover {
  transform: scale(1.05);
  filter: brightness(1.05);
}

.success,
.error {
  color: var(--primary-color);
  display: block;
  padding: 1rem 0;
}
.success {
  color: green;
}
</style>
