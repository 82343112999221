<template>
  <div>
    <a
      @click.prevent="toggleMainMenu()"
      class="mobile-menu"
      href="#"
      id="mobile-menu-btn"
      v-if="main_pages"
      aria-label="Menu"
    >
      <font-awesome-icon icon="bars" v-show="!showMenu" />
      <font-awesome-icon icon="times" v-show="showMenu" />
    </a>
    <nav :class="{ open: showMenu }" id="nav" v-if="main_pages">
      <div class="inner">
        <ul class="nav-root">
          <li v-for="menuPage in mainMenu" :key="menuPage.ItemID">
            <page-link :to="menuPage.url || '/'" :class="{ sel: topID == menuPage.ItemID }">{{
              menuPage.name
            }}</page-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      showMenu: false
    }
  },
  computed: {
    main_pages() {
      return this.$store.state.main_pages
    },
    mainMenu() {
      return this.$store.getters.mainMenu
    },
    page() {
      return this.$store.state.page
    },
    topID() {
      if (this.$store.state.parents.length) return this.$store.state.parents[0].ItemID

      return this.page.ItemID
    }
  },
  methods: {
    toggleMainMenu() {
      this.showMenu = !this.showMenu
      document.body.classList.toggle("fixed")
    },
    toggleSubMenu: (event) => {
      var li = event.srcElement.closest("li")
      li.classList.toggle("open")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
nav {
  /*background: repeating-linear-gradient(
    45deg,
    var(--tertiary-color),
    var(--tertiary-color) 10px,
    #efefef 10px,
    #efefef 20px
  );*/
  background: var(--tertiary-color);
}

nav .inner {
  pointer-events: none;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  z-index: 1;
}

nav .inner > ul > li {
  display: inline-block;
  margin-right: 4.5%;
}

nav .inner > ul > li:last-child {
  margin-right: 0%;
}

nav a {
  pointer-events: all;
  display: block;
  padding: 0;
  font-size: 1.6rem;
  text-decoration: none;
  line-height: 5rem;
  transition: all 0.3s;
  white-space: nowrap;
}

nav ul ul a {
  padding: 1rem 2rem;
  line-height: 1.4;
  margin-right: 0;
  text-transform: none;
  border-bottom: 1px solid rgba(91, 186, 70, 0.5);
  z-index: 1;
  position: relative;
}

nav ul ul ul a {
  background: #e9f3e3;
}

nav a:hover,
nav a.sel {
  color: var(--primary-color);
}

nav ul ul a:hover {
  color: #333;
}

/* Hide Dropdowns by Default */
nav ul ul {
  display: none;
  position: absolute;
}

/* Fisrt Tier Dropdown */
nav ul ul li {
  width: auto;
  float: none;
  display: list-item;
  position: relative;
}

/* Second, Third and more Tiers	*/
nav ul ul ul {
  position: absolute;
  top: 0;
  left: 100%;
}
nav .down {
  display: none;
  padding: 0.8rem 1rem;
  padding-left: 1.5rem;
  top: -0.8rem;
  right: -1rem;
  position: relative;
}

nav .down .fa-chevron-up {
  display: none;
}

.mobile-menu {
  display: none;
  position: absolute;
  left: 3%;
  font-size: 4.5rem;
  color: var(--primary-color);
  z-index: 3;
}

.mobile-menu > * {
  position: absolute;
  left: 0;
  top: 3.4rem;
  transition: all 0.2s;
}

.mobile-menu .fa-times {
  margin-left: 0.5rem;
}

@media screen and (min-width: 1091px) {
  /* Display Dropdowns on Hover */
  .mobile {
    display: none;
  }
  nav ul li:hover > ul {
    display: inherit;
  }
  nav {
    text-transform: uppercase;
    pointer-events: all;
  }

  nav ul ul a::before {
    display: block;
    content: " ";
    width: 1px;
    height: 100%;
    background: rgba(91, 186, 70, 0.5);
    z-index: 2;
    top: 0;
    left: 0;
    position: absolute;
    transition: all 0.3s;
  }
  nav ul ul a:hover::before {
    width: 1rem;
    background: var(--primary-color);
  }
}

@media screen and (max-width: 1500px) {
  nav .inner > ul > li {
    display: inline-block;
    margin-right: 3%;
  }
}

@media screen and (max-width: 1200px) {
  nav .inner > ul > li {
    display: inline-block;
    margin-right: 2.5%;
  }
}

@media screen and (max-width: 1090px) {
  nav {
    background: white;
    position: fixed;
    top: 12.5rem;
    right: 100%;
    width: 100%;
    transition: all 0.3s;
    z-index: 9999;
    height: calc(100vh - 12.5rem);
    overflow: auto;
    padding-bottom: 8rem;
  }
  nav .inner {
    width: 100%;
  }

  nav .down {
    display: block;
    float: right;
  }
  nav.open {
    right: 0;
  }
  nav ul {
    padding-bottom: 2rem;
  }

  .nav-root > li:first-child a {
    margin-right: 0;
    margin-left: 0;
  }
  nav ul a {
    padding: 1rem 3%;
    line-height: 1.2;
    font-size: 2rem;
    margin-right: 0;
  }
  nav .inner > ul > li {
    display: block;
    margin-right: 0;
  }

  nav ul ul,
  nav ul ul ul {
    position: relative;
    top: 0;
    left: 2rem;
    width: calc(100% - 4rem);
    padding-bottom: 1rem;
  }
  nav ul ul ul {
    left: 0;
    width: 100%;
  }

  nav ul li.open > ul {
    display: block;
  }

  nav .up,
  nav .down {
    margin-right: 1.5%;
    pointer-events: none;
  }

  nav li.open > a > .down .fa-chevron-up {
    display: block;
  }

  nav li.open > a > .down .fa-chevron-down {
    display: none;
  }

  .mobile-menu {
    display: block;
    top: 1.4rem;
  }

  nav ul a {
    font-size: 1.8rem;
  }
  nav ul ul a {
    font-size: 1.6rem;
  }

  nav ul ul .down,
  nav ul ul .up {
    margin-right: 0.1%;
  }
  nav ul ul ul a {
    font-size: 1.4rem;
    padding-left: 4rem;
  }
}

@media screen and (max-width: 770px) {
  nav {
    top: 11rem;
    height: calc(100vh - 11rem);
  }
  .mobile-menu > * {
    top: 2rem;
  }
  .mobile-menu {
    font-size: 3rem;
  }
}

@media screen and (max-width: 500px) {
  nav {
    top: 70px;
    right: 100%;
    width: 100%;
    transition: all 0.3s;
    z-index: 9999;
    height: calc(100vh - 70px);
  }
}
</style>
