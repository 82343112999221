<template>
  <div class="inner">
    <section class="grid">
      <a v-for="image in page.gallery" :key="image._id" class="photos" :href="image.image" :title="image.description"
        ><span class="link-image" :style="'background-image: url(' + encodeURI(image.image) + ')'"></span
        ><span class="link-label">{{ image.name }}</span></a
      >
    </section>
  </div>
</template>

<script>
import { LuminousGallery } from "luminous-lightbox"
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  },
  created: function () {
    this.$nextTick().then(() => {
      new LuminousGallery(
        document.querySelectorAll(".photos"),
        {
          arrowNavigation: true
        },
        {
          caption: function (trigger) {
            return trigger.getAttribute("title")
          }
        }
      )
    })
  }
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  margin-bottom: 5rem;
}
.grid a {
  position: relative;
  overflow: hidden;
  padding-top: 70%;
}
.link-image {
  display: block;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}
.grid a:hover .link-image {
  transform: scale(1.1);
  filter: brightness(1.1);
}
.link-label {
  background: var(--primary-color);
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  padding: 1rem 2.5rem 1rem 2.5rem;
  transition: all 0.3s;
  line-height: 1.2;
}
.grid a:hover .link-label {
  padding: 1rem 2.5rem 2rem 3.5rem;
}
.link-label span {
  display: block;
  font-size: 2rem;
}

@media screen and (max-width: 770px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    margin-top: 3rem;
  }
  .grid a {
    height: 25rem;
  }
  .link-image {
    background-position: bottom right;
  }

  .grid a:hover .link-image {
    transform: none;
    filter: none;
  }
  .link-label,
  .grid a:hover .link-label {
    padding: 0.5rem 1.25rem 1rem 1.25rem;
  }

  .link-label span {
    font-size: 1.7rem;
  }
}
</style>
