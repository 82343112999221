<template>
  <section class="content" :class="{ padded: isHomePage || isATopCategory }">
    <div class="inner">
      <h1 v-if="isASubCategory">{{ page.name }}</h1>
      <span v-html="page.main_text"></span>

      <div v-if="isSuccess || isFailure">
        <p v-if="!page.transaction_response">Please wait while we finalise your order...</p>
        <p v-if="page.transaction_response && page.transaction_response.message == 'OK'">
          Your payment was successful! Your order number is
          <strong>{{ page.transaction_response.orderID }}</strong
          >. Confirmation has been emailed to you.
        </p>
        <p v-if="page.transaction_response && page.transaction_response.message != 'OK'">
          <span v-if="page.transaction_response.message == 'EmailError'"
            >You will receive an email containing your order details, please contact us if it doesn't come through</span
          >
          <span v-else>{{ page.transaction_response.details }}</span>
        </p>
      </div>

      <img v-if="page.banner_image" :src="page.banner_image" :alt="page.banner_alt" class="banner" />
      <div class="split" v-if="isHomePage">
        <div v-html="page.main_text_2"></div>
        <div v-html="page.main_text_3"></div>
      </div>

      <template v-if="page.url == 'basket'">
        <h1>Basket</h1>
      </template>
      <template v-if="page.url == 'account'">
        <h1>My Account</h1>
      </template>
      <template v-if="page.url == 'checkout'">
        <h1>Checkout</h1>
      </template>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage", "isATopCategory", "isASubCategory", "isSuccess", "isFailure"])
  }
}
</script>

<style scoped>
.content {
  padding-bottom: 5rem;
}

.content.padded {
  padding-top: 5rem;
}

.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8rem;
  row-gap: 3rem;
  margin-top: 5rem;
}

.banner {
  margin-top: 5rem;
}

@media screen and (max-width: 770px) {
  .content {
    padding-bottom: 0;
  }
  .content.padded {
    padding-top: 3rem;
  }
  .split {
    grid-template-columns: 1fr;
    row-gap: 0;
    margin-top: 3rem;
  }
}
</style>
