<template>
  <section class="cart">
    <div class="inner" v-if="basket && basket.items.length > 0">
      <div class="left">
        <cart-table :basket="basket" :dynamic="true"></cart-table>
        <input @click="emptyCart" class="empty" type="button" value="Empty Cart" />
      </div>

      <div class="right">
        <table class="totals">
          <tr>
            <td>Sub Total</td>
            <td>&pound;{{ subTotal.toFixed(2) }}</td>
          </tr>
          <tr>
            <td>VAT @ 20%</td>
            <td>&pound;{{ vat.toFixed(2) }}</td>
          </tr>
          <tr>
            <td>Delivery</td>
            <td v-if="delivery">&pound;{{ delivery.toFixed(2) }}</td>
            <td v-else>FREE</td>
          </tr>
          <tr class="total">
            <td>TOTAL inc VAT</td>
            <td>&pound;{{ grandTotal.toFixed(2) }}</td>
          </tr>
        </table>

        <page-link :href="checkoutPage.url" class="buy" v-if="grandTotal && checkoutPage"
          >Proceed to checkout</page-link
        >
      </div>
    </div>
    <div class="inner" v-else>
      <p>Your basket is empty!</p>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"
import CartTable from "./CartTable.vue"

export default {
  components: {
    CartTable
  },
  computed: {
    ...mapState(["basket", "checkoutPage"]),
    ...mapGetters({
      subTotal: "basketSubTotal",
      delivery: "basketDelivery",
      grandTotal: "basketGrandTotal",
      vat: "basketVAT"
    })
  },
  methods: {
    changeQty(index, quantity) {
      this.$store.commit("basketUpdateQty", { index, quantity })
    },
    emptyCart() {
      this.$store.commit("basketEmptyCart")
    },
    edit(item) {
      return item
    }
  }
}
</script>

<style scoped>
.cart {
  padding-top: 0;
  padding-bottom: 8rem;
}

.left {
  width: 70%;
  margin-right: 5%;
  float: left;
}

.right {
  width: 25%;
  float: left;
}

.totals {
  border: 3px solid var(--primary-color);
  padding: 2rem;
  width: 100%;
  font-size: 2rem;
}

.total {
  font-weight: bold;
}

.total td {
  padding-top: 2rem;
  font-size: 2rem;
}

.buy {
  margin-top: 3rem;
  display: block;
  background: linear-gradient(to right, #00933b 50%, #007c32 50%);
  background-size: 200% 100%;
  transition: all 0.2s;
  color: white;
  text-align: center;
  font-size: 1.8rem;
  padding: 2rem;
  text-transform: uppercase;
  font-weight: 900;
}

.buy:hover {
  background-position: right bottom;
}

.basket {
  width: 100%;
  margin-bottom: 3rem;
  border-collapse: collapse;
}

.qty {
  width: 5rem;
  margin: 0 1rem;
}

.tdqty {
  text-align: center;
}

.tdqty input {
  text-align: center;
}

.basket thead td {
  background: ;
  font-weight: 900;
}

.basket td {
  padding: 1.5rem;
  border-bottom: 2px solid #f5f5f5;
}

.details {
  display: block;
  font-size: 1.3rem;
  opacity: 0.8;
}

.qtylink {
  color: #ccc;
}

.qtylink:hover {
  color: #cd0b0f;
}

.empty {
  background: #f5f5f5;
  padding: 0.75rem 1.25rem;
  border: 0;
  cursor: pointer;
}

.empty:hover {
  background: #e6e6e6;
}

@media screen and (max-width: 850px) {
  .left,
  .right {
    margin: 0;
    width: 100%;
  }
  .right {
    margin-top: 3rem;
  }
}
</style>
