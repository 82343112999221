<template>
  <table class="basket">
    <thead>
      <tr>
        <td>Product</td>
        <td class="tdqty">Length (mm)</td>
        <td class="tdqty">Quantity</td>
        <td>Price</td>
      </tr>
    </thead>
    <tbody>
      <tr :key="item._id" v-for="(item, index) in basket.items">
        <td>
          <span class="name">{{ item.variant.name }}</span>
          <!-- <span class="details">SKU: IK 100-0200</span> -->
        </td>
        <td class="tdqty">
          <template v-if="item.length_mm">
            <a class="qtylink" href>
              <font-awesome-icon @click.prevent="changeLength(index, item.length_mm - 10, item)" icon="minus-square" />
            </a>
            <input
              @change="changeLength(index, $event.target.value, item)"
              class="qty-mm"
              type="text"
              v-model.number="item.length_mm"
            />
            <a @click.prevent="changeLength(index, item.length_mm + 10, item)" class="qtylink" href>
              <font-awesome-icon icon="plus-square" />
            </a>
          </template>
        </td>
        <td class="tdqty">
          <a class="qtylink" href>
            <font-awesome-icon @click.prevent="changeQty(index, item.qty - 1)" icon="minus-square" />
          </a>
          <input @change="changeQty(index, $event.target.value)" class="qty" type="text" v-model.number="item.qty" />
          <a @click.prevent="changeQty(index, item.qty + 1)" class="qtylink" href>
            <font-awesome-icon icon="plus-square" />
          </a>
        </td>
        <td class="price">&pound;{{ calculateItemPrice(item).toFixed(2) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["basket", "dynamic"],
  methods: {
    changeQty(index, qty) {
      qty = Math.floor(qty)
      this.$store.commit("basketUpdateQty", { index, qty })
    },
    changeLength(index, length_mm, item) {
      if (length_mm <= 0) return
      if (item.variant.min_length) {
        if (length_mm < item.variant.min_length) length_mm = item.variant.min_length
      }
      this.$store.commit("basketUpdateLength", { index, length_mm })
    },
    calculateItemPrice(item) {
      return this.$store.state.calculateItemPrice(item)
    }
  }
}
</script>

<style scoped>
.cart {
  padding: 4rem 0;
}

.left {
  width: 70%;
  margin-right: 5%;
  float: left;
}

.right {
  width: 25%;
  float: left;
}

.totals {
  border: 3px solid var(--primary-color);
  padding: 2rem;
  width: 100%;
  font-size: 2rem;
}

.total {
  font-weight: bold;
}

.total td {
  padding-top: 2rem;
  font-size: 2.5rem;
}

.buy {
  margin-top: 3rem;
  display: block;
  background: linear-gradient(to right, #00933b 50%, #007c32 50%);
  background-size: 200% 100%;
  transition: all 0.2s;
  color: white;
  text-align: center;
  font-size: 1.8rem;
  padding: 2rem;
  text-transform: uppercase;
  font-weight: 900;
}

.buy:hover {
  background-position: right bottom;
}

.basket {
  width: 100%;
  margin-bottom: 3rem;
  border-collapse: collapse;
}

.qty,
.qty-mm {
  width: 5rem;
  margin: 0 1rem;
}

.qty-mm {
  width: 7rem;
}

.tdqty {
  text-align: center;
}

.tdqty input {
  text-align: center;
}

.basket thead td {
  background: var(--tertiary-color);
  font-weight: 900;
}

.basket td {
  padding: 1.5rem;
  border-bottom: 2px solid var(--tertiary-color);
}

.details {
  display: block;
  font-size: 1.3rem;
  opacity: 0.8;
}

.qtylink {
  color: #ccc;
}

.qtylink:hover {
  color: #cd0b0f;
}

.empty {
  background: var(--tertiary-color);
  padding: 0.75rem 1.25rem;
  border: 0;
  cursor: pointer;
}

.empty:hover {
  background: #e6e6e6;
}

@media screen and (max-width: 850px) {
  .left,
  .right {
    margin: 0;
    width: 100%;
  }
  .right {
    margin-top: 3rem;
  }
  .qtylink {
    display: none;
  }
}
</style>
