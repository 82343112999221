<template>
  <section class="content">
    <div class="inner">
      <div>
        <div class="left">
          <h2>Billing Details</h2>

          <div class="row">
            <label>
              First name
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.billing.first_name" />
          </div>
          <div class="row">
            <label>
              Surname
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.billing.surname" />
          </div>
          <div class="row">
            <label>
              Address
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.billing.address_1" />
          </div>
          <div class="row">
            <label></label>
            <input class="txt" type="text" v-model="form.billing.address_2" />
          </div>
          <div class="row">
            <label>
              Town / City
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.billing.town_city" />
          </div>
          <div class="row">
            <label>
              County
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.billing.county" />
          </div>
          <div class="row">
            <label>
              Postcode
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.billing.post_code" />
          </div>

          <h2>Contact Details</h2>
          <div class="row">
            <label>Telephone</label>
            <input class="txt" type="tel" v-model="form.contact.telephone" />
          </div>
          <div class="row">
            <label>Mobile</label>
            <input class="txt" type="tel" v-model="form.contact.mobile" />
          </div>
          <div class="row">
            <label>
              Email
              <span class="req">*</span>
            </label>
            <input class="txt" type="email" v-model="form.contact.email" />
          </div>
        </div>

        <div class="right">
          <h2>Delivery Details</h2>

          <p class="same">
            <a @click.prevent="copyFromBilling" href> <i class="fa fa-copy"></i> Copy from billing details </a>
          </p>

          <div class="row">
            <label>
              First name
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.delivery.first_name" />
          </div>
          <div class="row">
            <label>
              Surname
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.delivery.surname" />
          </div>
          <div class="row">
            <label>
              Address
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.delivery.address_1" />
          </div>
          <div class="row">
            <label></label>
            <input class="txt" type="text" v-model="form.delivery.address_2" />
          </div>
          <div class="row">
            <label>
              Town / City
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.delivery.town_city" />
          </div>
          <div class="row">
            <label>
              County
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model="form.delivery.county" />
          </div>
          <div class="row">
            <label>
              Postcode
              <span class="req">*</span>
            </label>
            <input class="txt" type="text" v-model.lazy="form.delivery.post_code" />
          </div>
          <div class="row">
            <label>
              Country
              <span class="req">*</span>
            </label>
            <select class="txt" type="text" v-model="form.delivery.country">
              <option value="UK">United Kingdom</option>
              <option value="RoW">Rest of the world</option>
            </select>
          </div>
          <div class="row">
            <label>Delivery Instructions</label>
            <textarea class="txt" v-model="form.delivery.instructions"></textarea>
          </div>
        </div>
      </div>

      <div class="actions">
        <!-- <p class="account" v-if="!logged_in">
          <input id="chkaccount" type="checkbox" v-model="form.account.createaccount" />
          <label for="chkaccount">&nbsp;Create an account to speed up future orders</label>
        </p> -->

        <!-- <div class="row note" v-show="form.account.createaccount">
          Choose a password with at least 8 characters, including letters and numbers
        </div>
        <div class="row" v-show="form.account.createaccount">
          <label>Password</label>
          <input class="txt" type="password" v-model="form.account.password" />
        </div>
        <div class="row" v-show="form.account.createaccount">
          <label>Confirm</label>
          <input class="txt" type="password" v-model="form.account.confirm" />
        </div> -->

        <p class="terms">
          <input id="chkterms" type="checkbox" v-model="form.terms_agreed" />
          <label for="chkterms">
            I have read and accepted the
            <a href="/terms-conditions" rel="nofollow" target="_blank">terms &amp; conditions</a>
          </label>
        </p>

        <span class="success" v-show="success">Thanks, your request has been sent!</span>
        <span class="error" v-show="error">{{ errormessage }}</span>

        <template v-if="validPostcode && form.delivery.country == 'UK'">
          <a @click.prevent="checkout" class="buy" v-if="grandTotal">Proceed to payment</a>
        </template>
        <template v-if="form.delivery.country !== 'UK'">
          <p class="error">For deliveries outside the UK, please contact us on 0845 130 2299. Thank you!</p>
        </template>
        <!-- <p class="error" v-if="!validPostcode">Please contact our sales office for delivery to this location</p> -->
      </div>
    </div>

    <form action="https://live.sagepay.com/gateway/service/vspform-register.vsp" id="paymentform" method="post">
      <input id="VPSProtocol" name="VPSProtocol" type="hidden" />
      <input id="TxType" name="TxType" type="hidden" />
      <input id="Vendor" name="Vendor" type="hidden" />
      <input id="Crypt" name="Crypt" type="hidden" />
    </form>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { mapGetters } from "vuex"

import api from "@/services/api"
// import cognito from "@/services/cognito"

export default {
  name: "PageCheckout",
  data: function () {
    return {
      logged_in: false,
      userID: "",
      success: false,
      error: false,
      errormessage: "",
      form: {},
      initial_values: {
        account: {},
        billing: { country: "UK" },
        contact: {},
        delivery: { country: "UK" }
      }
    }
  },
  computed: {
    ...mapState(["basket", "settings"]),
    ...mapGetters({ subTotal: "basketSubTotal", grandTotal: "basketGrandTotal" }),
    validPostcode() {
      return true

      // if (!this.form.delivery.post_code) return true
      // if (!this.settings) return true
      // if (!this.settings.restricted_postcodes) return true

      // var codes = this.settings.restricted_postcodes.split(",")
      // var delpostcode = this.form.delivery.post_code.replace(/ /g, "").slice(0, -3).toLowerCase()

      // var valid = true

      // codes.forEach((code) => {
      //   code = code.trim().toLowerCase()

      //   if (code.length) {
      //     // If letters only, compare letters section of customer post code
      //     if (/^[a-z]+$/.test(code)) {
      //       var match = delpostcode.match(/^\D+/)
      //       if (match && match[0] == code) valid = false
      //       // If includes numbers, compare to first section of customer post code
      //     } else if (delpostcode == code) {
      //       valid = false
      //     }
      //   }
      // })

      // return valid
    }
  },
  created: function () {
    this.form = JSON.parse(JSON.stringify(this.initial_values))

    // cognito
    //   .currentUserSession()
    //   .then((s) => {
    //     this.logged_in = true
    //     this.userID = s.username

    //     api.getUser(this.userID).then((user) => {
    //       this.form.billing = user.billing
    //       this.form.delivery = user.delivery
    //       this.form.contact = user.contact
    //     })
    //   })
    //   .catch(() => {})
  },
  methods: {
    checkout: function () {
      this.success = false
      this.error = false

      if (
        !this.form.billing.first_name ||
        !this.form.billing.surname ||
        !this.form.billing.address_1 ||
        !this.form.billing.town_city ||
        !this.form.billing.county ||
        !this.form.billing.post_code ||
        !this.form.delivery.first_name ||
        !this.form.delivery.surname ||
        !this.form.delivery.address_1 ||
        !this.form.delivery.town_city ||
        !this.form.delivery.county ||
        !this.form.delivery.post_code ||
        !this.form.contact.email
      ) {
        this.errormessage = "Please fill in all required fields, thanks!"
        this.error = true
        return false
      }

      if (!this.form.terms_agreed) {
        this.errormessage = "Please agree to the terms & conditions, thanks!"
        this.error = true
        return false
      }

      // var p = []
      // if (this.form.account.createaccount) {
      //   if (this.form.account.password != this.form.account.confirm) {
      //     this.errormessage = "Passwords do not match"
      //     this.error = true
      //     return false
      //   } else {
      //     p.push(cognito.signUp(this.form.contact.email, this.form.account.password))
      //   }
      // }

      // Promise.all(p)
      //   .then((vals) => {
      var form = this.form

      //     if (vals.length) {
      //       this.userID = vals[0]
      //       api.createUser(this.userID, { billing: form.billing, contact: form.contact, delivery: form.delivery })
      //     }
      //     if (this.logged_in)
      //       api.updateUser(this.userID, { billing: form.billing, contact: form.contact, delivery: form.delivery })

      var basket = JSON.parse(JSON.stringify(this.basket))
      basket.sub_total = this.subTotal
      basket.grand_total = this.grandTotal

      api
        .generateSagePayFields({
          form: { billing: form.billing, contact: form.contact, delivery: form.delivery },
          basket,
          userID: this.userID
        })
        .then((res) => {
          document.getElementById("VPSProtocol").value = res.data.VPSProtocol
          document.getElementById("TxType").value = res.data.TxType
          document.getElementById("Vendor").value = res.data.Vendor
          document.getElementById("Crypt").value = res.data.Crypt

          var theForm = document.getElementById("paymentform")
          theForm.submit()
        })
        .catch((err) => {
          if (err == "Basket empty") {
            this.$store.commit("basketEmptyCart")
            this.$router.push("cart")
          } else if (err == "Restricted postcode") {
            this.errormessage = "Please contact our sales office for delivery to this location"
            this.error = true
          } else if (err == "Min length") {
            this.errormessage = "Please check the lengths you have selected"
            this.error = true
          } else {
            this.errormessage = err
            this.error = true
          }
        })
      // })
      // .catch((err) => {
      //   var code = err.code
      //   var msg = err.message

      //   if (code == "InvalidParameterException") {
      //     msg = "Please check your password has at least 8 characters, including letters and numbers"
      //   }
      //   if (code == "UserNotConfirmedException") {
      //     msg =
      //       "A user with this email address already exists, but the account has not been actived. Please check your email for instructions."
      //   }
      //   if (code == "UsernameExistsException") {
      //     msg = "Looks like you already have an account with us, please sign in!"
      //   }

      //   this.errormessage = msg
      //   this.error = true
      // })
    },
    copyFromBilling: function () {
      var instructions = this.form.delivery.instructions
      this.form.delivery = JSON.parse(JSON.stringify(this.form.billing))
      this.form.delivery.instructions = instructions
    }
  }
}
</script>

<style scoped>
.login {
  font-size: 1.8rem;
}

.content {
  padding-bottom: 5rem;
}

.login a {
  color: #cd0b0f;
}

h2 {
  margin-top: 2rem;
}

.row {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  width: 100%;
  display: table;
}

textarea {
  height: 8rem;
}

.txt {
  border: 1px solid #d5d5d5;
  width: 30rem;
  padding: 1rem;
  float: left;
}

.row label {
  display: block;
  float: left;
  width: 20rem;
  padding-top: 0.5rem;
}

.same {
  padding-top: 0;
  margin-top: 0;
}

.note {
  font-size: 1.2rem;
  padding: 1rem 0;
  font-style: italic;
}

.terms a,
.same a,
.req {
  color: #cd0b0f;
}

.terms,
.account {
  padding-top: 1rem;
  clear: both;
  cursor: pointer;
}

.account {
  padding-top: 2rem;
}

.terms input,
.account input {
  margin-right: 0.5rem;
}

.heading {
  padding-top: 4rem;
}

i {
  color: #ccc;
  margin-right: 0.5rem;
}

.error {
  color: #cc0000;
  display: block;
  padding: 1rem 0;
}

.left,
.right {
  width: 50%;
  float: left;
}

.buy {
  margin-top: 3rem;
  background: linear-gradient(to right, #00933b 50%, #007c32 50%);
  background-size: 200% 100%;
  transition: all 0.2s;
  color: white;
  text-align: center;
  font-size: 1.8rem;
  padding: 2rem;
  text-transform: uppercase;
  font-weight: 900;
  cursor: pointer;
  margin: 2rem 0;
  display: inline-block;
}

.buy:hover {
  background-position: right bottom;
}

h2 {
  padding-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .row label {
    width: 30%;
    padding-right: 5%;
  }
  .row .txt {
    width: 65%;
  }

  .left,
  .right {
    width: 100%;
    float: none;
  }
}

@media screen and (max-width: 600px) {
  .config-buy {
    width: 100%;
  }
}
</style>
